import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";

    styled.css `

        & [data-revealed][data-on-reveal=zoom-in] {
            animation: zoom-in 1s ease;
        }

        @keyframes zoom-in {
            0% {
                transform: scale(0.5, 0.5);
                opacity: 0.3;
            }
            100% {
                transform: scale(1, 1);
                opacity: 1;
            }
        }

    `.installGlobal("");

export const reveal = new IntersectionObserver((entries) => {

    for (const iterator of entries) {
        if(iterator.isIntersecting) {
            reveal.unobserve(iterator.target);
            iterator.target.setAttribute("data-revealed", "true");
        }
    }

});

ComponentHelper.waitForReady().then(() => {
    const all = document.querySelectorAll("[data-on-reveal]");
    for (let index = 0; index < all.length; index++) {
        const element = all[index];
        if (element.hasAttribute("data-revealed")) {
            continue;
        }
        reveal.observe(element);
    }
});