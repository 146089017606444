import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";
import { installCss } from "../../core/installCss";
import Modules from "../../core/Modules";
import { XNode } from "../../core/XNode";
import { PdfFrame } from "./PdfFrame";
import "./PdfPage";

    styled.css `
    cursor: pointer;
    display: inline-block;
    max-width: 100vw;
    overflow: hidden;
    padding: 10px;
    & > * {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    `.installGlobal("pdf-viewer");

/**
 * Due to License Requirements and Trouble of setting up IFrame and loading
 * relative resources, This component was created to utilize PDF directly in the
 * page without having to go through IFRAME.
 */

export class PdfViewer extends HTMLElement {

    connectedCallback() {
        this.prepare().catch(console.error);
    }

    async prepare() {

        await ComponentHelper.waitForReady();

        const src = this.getAttribute("src");


        const renderText = /yes|on|true/i.test(this.getAttribute("render-text"));

        const allowDownload = this.hasAttribute("allow-download");

        await Modules.import("https://mozilla.github.io/pdf.js/build/pdf.mjs");

        let { pdfjsLib } = globalThis as any;
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://mozilla.github.io/pdf.js/build/pdf.worker.mjs';

        let pdfDoc;


        const poster = this.getAttribute("poster");
        if (poster) {
            const img = document.createElement("img");
            img.src = poster;
            this.appendChild(img);
        } else {

            pdfDoc = await pdfjsLib.getDocument(src).promise;
            const firstPage = await pdfDoc.getPage(1);
            const viewport = firstPage.getViewport({ scale: 0.5 });

            const canvas = document.createElement("canvas");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const canvasContext = canvas.getContext("2d");
            firstPage.render({
                canvasContext,
                viewport
            }).promise
            this.appendChild(canvas);
        }

        this.addEventListener("click", () => {
            this.loadPdf(pdfDoc, renderText, pdfjsLib, src, allowDownload);
        });
    }


    private async loadPdf(pdfDoc: any, renderText: boolean, pdfjsLib: any, src: string, allowDownload: boolean) {

        pdfDoc ??= await pdfjsLib.getDocument(src).promise;


        const pdfFrame = document.createElement("pdf-frame") as any as PdfFrame;
        pdfFrame.pdfDoc = pdfDoc;
        pdfFrame.showClose = true;
        const { style } = pdfFrame;
        style.position = "absolute";
        style.left = "0";
        style.top = "0";
        style.width = "100vw";
        style.height = "100vh";
        style.zIndex = "10000";

        if (renderText) {
            pdfFrame.TextLayer = pdfjsLib.TextLayer;
        }

        pdfFrame.setAttribute("src", src);
        if (allowDownload) {
            pdfFrame.setAttribute("allow-download", "true");
        }
        document.body.appendChild(pdfFrame);
    }
}

customElements.define("pdf-viewer", PdfViewer);